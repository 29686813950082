import React, { useState } from 'react';
import { onPaginationHelper } from '~/app/utils/pagination-helpers';
import { actions } from '~/data/index.js';
import { useAppDispatch } from '~/data/utils/hooks';
import { useHistory, useParams } from 'react-router-dom';
import { particlesPagePath } from '~/app/constants/url/auditor';
import { formatDate } from '~/app/utils/format-data';
import ReactTooltip from 'react-tooltip';
import { Row, Text } from '~/ui/index.js';
import {
  AlertColor,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import ReactDOMServer from 'react-dom/server';
import TableRowsLoader from '~/ui/components/mui-table/table-loader';
import TablePagination from '~/ui/components/table-pagination/index';
import Toast from '~/ui/components/toast/index';

import s from './styles.module.scss';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeyBuilder } from '~/data/utils/helpers/query-key.builder';
import { dazzler } from '~/data/dazzler-api';
import { AuditLogRepresentation, LogActorRepresentation } from '~/data/openapi-client/index';
import { z } from 'zod';
import { fromThrowable } from 'neverthrow';
import { assert } from '@std/assert';
import { ListRepresentation } from '~/data/utils/types';

/**
 * These are internal properties taken somewhere from the API
 */
const ContextSchema = z.object({
  Status: z.string(),
  OldVersion: z.string(),
  NewVersion: z.string().nullish(),
  Content: z.any().nullish(),
  Headers: z.string().nullish(),
});

function createDefaultContext(): z.infer<typeof ContextSchema> {
  return {
    Status: 'Unknown',
    OldVersion: '',
    NewVersion: '',
    Content: '',
    Headers: '{}',
  };
}

assert(
  ContextSchema.safeParse(createDefaultContext()).success,
  'The default context should align with validation schema',
);

const safeJSON = fromThrowable(JSON.parse);

function parseActor(actor: LogActorRepresentation): string {
  try {
    return actor.type === 'User' ? JSON.parse(actor.properties ?? '{}').Email : actor.type;
  } catch (err) {
    return actor.type;
  }
}

const HeaderSchema = z.object({
  Name: z.string(),
  Value: z.string(),
});

function formatHeaders(headers: string | undefined | null): React.JSX.Element[] {
  return safeJSON(headers || '[]')
    .andThen(fromThrowable(z.array(HeaderSchema).parse))
    .map((pairs) =>
      pairs.map((p) => (
        <li>
          ${p.Name}: ${p.Value}{' '}
        </li>
      )),
    )
    .unwrapOr([]);
}

export const ParticlesUpdates = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { page } = useParams<{ page: string }>();
  // @ts-expect-error - Conversion of type 'null' to type 'string' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
  const [updateJobId, setUpdateJobId] = useState(null as string);

  const [perPage, setPerPage] = useState(10);
  // @ts-expect-error - Conversion of type 'null' to type '{ type: AlertColor; message: string; }' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
  const [toastInfo, setToastInfo] = useState(null as { type: AlertColor; message: string });

  const fetchJobStatus = (id) => dispatch(actions.internal.fetchJobStatus(id));
  const updateEndpoint = () => dispatch(actions.internal.updatePackages());

  const queryParams = {
    'paging.Limit': perPage,
    'paging.Offset': Number(page) > 1 ? (Number(page) - 1) * perPage : 0,
    type: 'ParticlesVersionUpdate',
  };

  const queryClient = useQueryClient();

  const {
    data: logList,
    isInitialLoading,
    isSuccess,
  } = useQuery({
    queryKey: queryKeyBuilder('internal', 'logs', queryParams),
    queryFn: async function listAuditLogs({
      signal,
    }): Promise<ListRepresentation<AuditLogRepresentation & { context: z.infer<typeof ContextSchema> }>> {
      const response = await dazzler.internal.getAuditLogs(
        {
          pagingLimit: queryParams['paging.Limit'],
          pagingOffset: queryParams['paging.Offset'],
        },
        { signal },
      );

      const items = response.data.items.map((x) => {
        const context = safeJSON(x.contextJson)
          .andThen(fromThrowable(ContextSchema.parse))
          .unwrapOr(createDefaultContext());
        return {
          ...x,
          context,
        };
      });

      return {
        items,
        count: response.data.count,
      };
    },
  });

  const onPagination = (e) => {
    setPerPage(e.target.value);
    onPaginationHelper(e.target);
  };

  const handleUpdateEndpoint = async () => {
    const res = await updateEndpoint();
    if (res?.id) {
      setUpdateJobId(res.id);
      setToastInfo({
        message: 'Update started. Please note that this may take up to 2 hours.',
        type: 'success',
      });
      const timer = setInterval(async () => {
        const statusRes = await fetchJobStatus(res.id);
        if (statusRes.status !== 'Succeeded' && statusRes.status !== 'Failed') return;
        setToastInfo({
          message: statusRes.status === 'Succeeded' ? 'Update succeeded' : 'Update failed',
          type: statusRes.status === 'Succeeded' ? 'success' : 'error',
        });
        queryClient.invalidateQueries({ queryKey: queryKeyBuilder('internal', 'logs', queryParams) });
        // @ts-expect-error - Argument of type 'null' is not assignable to parameter of type 'SetStateAction<string>'.
        setUpdateJobId(null);
        clearInterval(timer);
      }, 5000);
    }
  };
  return (
    <>
      <div className={s.imports}>
        <Box margin="32px 0 18px">
          <Text size={Text.size.s} weight={Text.weight.medium}>
            BR-AG Particles Updates
          </Text>
        </Box>
        <Row className={s.importsRow}>
          <Button
            variant="contained"
            onClick={handleUpdateEndpoint}
            data-tip={ReactDOMServer.renderToString(
              <Text size={Text.size.xs} color={Text.color.white} className={s.updateEndpointMsg}>
                Please note that it may take up to 2 hours to update the endpoint.
              </Text>,
            )}
            data-for="message"
            data-html
            loading={!!updateJobId || isInitialLoading}
            disabled={!!updateJobId || isInitialLoading}
          >
            <ReactTooltip id="message" />
            <Text className={s.updateEndpointMsg} size={Text.size.xs}>
              Call BR-AG Update Endpoint
            </Text>
          </Button>
        </Row>

        <Box marginTop="20px">
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="10%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.medium}>
                        Date
                      </Text>
                    </TableCell>
                    <TableCell width="10%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.medium}>
                        Status
                      </Text>
                    </TableCell>
                    <TableCell width="15%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.medium}>
                        Old Version
                      </Text>
                    </TableCell>
                    <TableCell width="15%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.medium}>
                        New Version
                      </Text>
                    </TableCell>
                    <TableCell width="15%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.medium}>
                        Actor
                      </Text>
                    </TableCell>
                    <TableCell width="35%">
                      <Text size={Text.size.s} font={Text.color.colorTextDark} weight={Text.weight.medium}>
                        Response
                      </Text>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isSuccess ? (
                    <>
                      {logList?.items?.length ? (
                        logList.items.map((item) => (
                          <TableRow key={item.id}>
                            <TableCell width="10%">
                              <Text
                                size={Text.size.s}
                                font={Text.color.colorTextMedium}
                                weight={Text.weight.semiMedium}
                              >
                                {formatDate(item.timestamp)}
                              </Text>
                            </TableCell>
                            <TableCell width="15%">
                              <Text
                                size={Text.size.s}
                                font={Text.color.colorTextMedium}
                                weight={Text.weight.semiMedium}
                              >
                                {item.context.Status}
                              </Text>
                            </TableCell>
                            <TableCell width="15%">
                              <Text
                                size={Text.size.s}
                                font={Text.color.colorTextMedium}
                                weight={Text.weight.semiMedium}
                              >
                                {item.context.OldVersion}
                              </Text>
                            </TableCell>
                            <TableCell width="15%">
                              <Text
                                size={Text.size.s}
                                font={Text.color.colorTextMedium}
                                weight={Text.weight.semiMedium}
                              >
                                {item.context.NewVersion}
                              </Text>
                            </TableCell>
                            <TableCell width="15%">
                              <Text
                                size={Text.size.s}
                                font={Text.color.colorTextMedium}
                                weight={Text.weight.semiMedium}
                              >
                                {parseActor(item.actor)}
                              </Text>
                            </TableCell>
                            <TableCell
                              width="35%"
                              data-tip={ReactDOMServer.renderToString(
                                <Text size={Text.size.xs} color={Text.color.white} className={s.updateEndpointMsg}>
                                  <strong>Headers:</strong>
                                  <br />
                                  <ul>{formatHeaders(item.context.Headers)}</ul>
                                </Text>,
                              )}
                              data-for="headers"
                              data-html
                            >
                              <ReactTooltip id="headers" />
                              <Text
                                size={Text.size.s}
                                font={Text.color.colorTextMedium}
                                weight={Text.weight.semiMedium}
                              >
                                {item.context.Content || 'empty'}
                              </Text>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <Text>No audit logs found</Text>
                      )}
                    </>
                  ) : (
                    <TableRowsLoader rowsNum={perPage} colsNum={6} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {isSuccess && !!logList?.items?.length && (
              <div className={s.importsPagination}>
                <TablePagination
                  totalItems={logList?.count}
                  perPage={perPage}
                  page={page}
                  handleChangePage={(p) => {
                    history.push(particlesPagePath(p + 1));
                  }}
                  onRowsPerPageChange={onPagination}
                />
              </div>
            )}
          </Paper>
        </Box>
      </div>
      <Toast
        open={!!toastInfo}
        // @ts-expect-error - Argument of type 'null' is not assignable to parameter of type 'SetStateAction<{ type: AlertColor; message: string; }>'.
        handleClose={() => setToastInfo(null)}
        message={toastInfo?.message}
        type={toastInfo?.type}
      />
    </>
  );
};
