import { Configuration } from './openapi-client/configuration';
import type { AxiosResponse, CreateAxiosDefaults } from 'axios';
import { baseURL } from '~/config';
import API from '~/data/api';
import {
  AuthApi,
  ClientApi,
  ClientComparisonApi,
  ClientsAdminApi,
  ClientsApi,
  ImportsApi,
  InternalApi,
  PowerBiExplanationApi,
  ReportsApi,
  TaxonomiesApi,
  UsersAdminApi,
  UsersApi,
} from '~/data/openapi-client/index';

export const apiConfig: CreateAxiosDefaults = {
  baseURL: baseURL.origin + '/',
};

export const api = new API(apiConfig);

export const dazzlerConfiguration = new Configuration({
  apiKey: () => api.getAuthorizationHeader() ?? '',
  baseOptions: apiConfig,
});

export const dazzler = {
  auth: new AuthApi(dazzlerConfiguration, undefined, api.axios),
  clients: new ClientsApi(dazzlerConfiguration, undefined, api.axios),
  client: new ClientApi(dazzlerConfiguration, undefined, api.axios),
  clientComparisons: new ClientComparisonApi(dazzlerConfiguration, undefined, api.axios),
  clientAdmins: new ClientsAdminApi(dazzlerConfiguration, undefined, api.axios),
  imports: new ImportsApi(dazzlerConfiguration, undefined, api.axios),
  powerBiExplanation: new PowerBiExplanationApi(dazzlerConfiguration, undefined, api.axios),
  reports: new ReportsApi(dazzlerConfiguration, undefined, api.axios),
  taxonomies: new TaxonomiesApi(dazzlerConfiguration, undefined, api.axios),
  usersAdmin: new UsersAdminApi(dazzlerConfiguration, undefined, api.axios),
  users: new UsersApi(dazzlerConfiguration, undefined, api.axios),
  internal: new InternalApi(dazzlerConfiguration, undefined, api.axios),
};

type PromiseResult<P extends Promise<any>> = P extends Promise<infer T> ? T : never;

// infer the data from axios response
export type EndpointResponse<
  Func extends (...args: any[]) => Promise<Res>,
  Res extends AxiosResponse = PromiseResult<ReturnType<Func>>,
> = Res['data'];

export type RequestOptions = {
  signal?: AbortSignal;
};
